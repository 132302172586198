.card {
  width: 20rem;
}

.card:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.card-body {
  padding: 3rem 4rem !important;
}

.card-title:hover {
  color: #f4b659;
}

.overflow {
  overflow: hidden;
}

.card-text {
  font-size: 0.9rem;
  padding: 0.4rem;
}

.card-img-top {
  transform: scale(1);
  transition: transform 0.5s ease;
}

.card-img-top:hover {
  transform: scale(1.8);
}
