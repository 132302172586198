.social__link-icon {
  text-decoration: none;

  color: #000d6b;
  font-weight: 500;
  font-size: 1.2rem;
}

.contact__btn {
  padding: 7px 15px;
  background: #000d6b;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 14px 4px;
  transition-duration: 0.5s;
}

.contact__btn:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.contact__btn:hover {
  background-color: white;
  color: #f9a827;
}

@media only screen and (max-width: 768px) {
  .contact__btn {
    margin-bottom: 40px;
    font-size: 0.8rem;
  }
}
