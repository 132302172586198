.featured {
  width: 100%;
  padding: 8rem 0;
  background-color: var(--background);
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 90%);
}

.featured .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.featured .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2rem;
}

.featured .left p {
  margin: 1.5rem 0;
}

.featured .left .btn {
  align-self: flex-start;
}

.featured .right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.featured .right .card {
  display: flex;
  flex-direction: column;
  padding: 12px 22px;
  margin: 0.4rem;
  width: 170px;
  height: 170px;
  border-radius: 24px;
  border: 1px solid #d3d3d3;
  box-shadow: rgb(0 0 0 / 12%) 0px 8px 24px;
  text-align: center;
}

.featured .right .card:hover {
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 24px;
}

.featured .card .top {
  display: flex;
  flex-direction: column;
}

.featured .right img {
  width: 50px;
  margin: auto;
  padding: 0.2rem 0;
}

.featured .icon {
  font-size: 36px;
  margin: auto;
  padding-top: 6px;
  justify-content: center;
  align-items: center;
}

.featured span {
  font-size: 22px;
}

.green {
  color: var(--primary);
}

.red {
  color: #ff0000;
}

.btnf {
  padding: 14px 32px;
  background-color: #000d67;
  color: #fbe1fb;
  border-radius: 24px 4px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  width: 180px;
  transition-duration: 0.4s;
  box-shadow: 10px 10px 5px #fbe1fb;
  border: gold;
}

.btnf:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.btnf:hover {
  background-color: white;
  color: #f9a827;
}

@media screen and (max-width: 940px) {
  .featured .container {
    grid-template-columns: 1fr;
  }

  .featured .left .btn {
    align-self: center;
  }
}

@media screen and (max-width: 478px) {
  .featured .container .right .card {
    width: 100%;
  }
}
