.become__driver {
  background: linear-gradient(rgb(0, 13, 107, 0.9), rgb(0, 13, 107, 0.9)),
    url("../assets/all-images/premium.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0px;
}

.become__driver button {
  background: #fff;
  color: #000d6b;
  font-weight: 600;
}

.become__driver-title {
  font-size: 2.8rem;
  color: #fff;
  line-height: 65px;
}

.btnst {
  padding: 14px 32px;
  background-color: #000d67;
  color: #fbe1fb;
  border-radius: 24px 4px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  width: 199px;
  transition-duration: 0.4s;
}

.btnst:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.btnst:hover {
  background-color: #fce9fc;
  color: #f9a827;
}

@media only screen and (max-width: 992px) {
  .become__driver-title {
    font-size: 1.5rem;
    line-height: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .become__driver {
    padding: 50px 0px;
  }

  .become__driver-title {
    font-size: 1.3rem;
    line-height: 2.5rem;
  }

  .become__driver-btn {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .become__driver-img {
    display: none;
  }
  .become__driver {
    text-align: center;
  }
}
