.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 4rem;
  transition: all 300ms ease;
}

.bars {
  display: none;
}

.logos {
  display: flex;
  height: 4%;
  font-weight: bold;
  font-size: 22px;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.logos > img {
  width: 3rem;
  height: 3rem;
}

.btnlgt, .btnwth {
  padding: 14px 32px;
  background-color: #000d67;
  color: #fbe1fb;
  border-radius: 24px 4px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition-duration: 0.4s;
}

.btnlgt {
  width: 100px;
}

.btnwth {
  width: 200px;
}

.btnlgt:active {
  background-color: #f32727;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.btnlgt:hover, .btnwth:hover {
  background-color: #ffe0bc;
  color: #f32727;
}

.menuTop {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.menuItemTop {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2.5rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 14px;
}

.menuItemTop:hover {
  cursor: pointer;
}

.active {
  background: #ffe0bc; /* Change to the desired active background color */
  margin-left: 0;
}

.active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: #f32727; /* Change to the desired active indicator color */
  margin-right: calc(1rem - 8px);
}

/* Media Queries */
@media screen and (max-width: 1200px) {
  .menuItemTop > span {
    display: none;
  }
  .logos {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    z-index: 9;
    background: #ffe0e0;
    width: 55%;
    padding-right: 1rem;
    height: 100%;
  }
  .menuItemTop > span {
    display: block;
  }
  .logos {
    display: flex;
  }
  .menuTop .menuItemTop:last-child {
    position: relative;
    margin-top: 6rem;
  }
  .bars {
    display: flex;
    position: fixed;
    top: 2rem;
    left: 60%;
    background: #ffe0e0;
    padding: 10px;
    border-radius: 10px;
    z-index: 9;
  }
}
