:root {
  --yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
  --pink: #ff919d;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}

.App {
  /* padding: 0.5rem 3.5rem; */
  color: var(--black);
  background: linear-gradient(
    106.37deg,
    #ffe1bc 19.63%,
    #ffff 51.55%,
    #ffff 90.85%
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
}

.AppGlass {
  display: grid;
  height: 97%;
  width: 97%;
  background: var(--glass);
  border-radius: 2rem;
  gap: 16px;
  grid-template-columns: 10rem auto;
  overflow: scroll;
}

@media screen and (max-width: 1200px) {
  .AppGlass {
    grid-template-columns: 10% 50% auto;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }
}
